import React from "react";

const CategoryPage = () => {
  return (
    <div className="w-full max-w-full relative overflow-hidden">
    &nbsp;
    </div>
  )
};

export default CategoryPage
